<app-header mainTitle="My Dashboard"></app-header>

<section class="client-portal appointments" [ngClass]="router.url == '/dashboard/purchases' ? 'purchases' : '' ">
    <div class="container grid">

        <!--Sidebar-->
        <ul class="small portal-links">
            <li routerLink="/dashboard/account" [routerLinkActive]="['active']"><a>Account Information</a></li>
            <li [ngClass]="(router.url=='/dashboard/reschedule' || router.url=='/dashboard/appointments') ? 'active' : '' "
                routerLink="/dashboard/appointments"><a>My Appointments</a></li>
            <li routerLink="/dashboard/purchases" [routerLinkActive]="['active']"><a>My Purchases</a></li>
            <li [ngClass]="router.url=='/dashboard/membership-products' ? 'active' : '' "
                routerLink="/dashboard/memberships" [routerLinkActive]="['active']"><a>My Memberships </a></li>
            <li *ngIf="dashboardService.$loyalty.value?.client"
                [ngClass]="router.url=='/dashboard/loyalty' ? 'active' : '' " routerLink="/dashboard/loyalty"
                [routerLinkActive]="['active']"><a>Loyalty Program</a></li>
        </ul>
        <div class="content">
            <h1 class="heading-4">Welcome back, {{getClientName()}}!</h1>
            <router-outlet></router-outlet>
        </div>
    </div>
</section>
<app-footer></app-footer>