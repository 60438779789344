
    <a href="https://silvermirror.com/" class="data-ui home-icon">
        <span>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="28" height="28" viewBox="0 0 192.000000 192.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M882 1529 c-52 -15 -107 -78 -122 -137 -14 -62 -3 -108 43 -167 29
                -38 408 -443 579 -620 l78 -80 0 434 0 435 -36 -35 c-19 -18 -99 -103 -177
                -187 -78 -84 -146 -151 -152 -150 -6 2 -66 61 -133 131 -123 131 -145 166
                -135 221 8 39 54 83 95 91 96 18 171 -89 123 -175 -12 -22 -11 -27 7 -47 12
                -13 25 -23 30 -23 18 0 48 81 48 132 0 129 -120 214 -248 177z m506 -696 l-3
                -126 -119 126 -118 127 118 127 119 126 3 -126 c1 -70 1 -184 0 -254z"/>
                <path d="M460 961 l0 -435 36 35 c19 18 99 103 177 187 78 84 146 151 152 150
                6 -2 66 -61 133 -131 123 -131 145 -166 135 -221 -8 -39 -54 -83 -95 -91 -96
                -18 -171 89 -123 175 12 22 11 27 -7 48 -29 30 -34 28 -58 -18 -70 -138 43
                -298 195 -276 122 19 195 158 141 268 -16 32 -350 396 -608 663 l-78 80 0
                -434z m309 -1 c0 -3 -53 -61 -119 -130 l-119 -125 0 255 0 255 119 -125 c66
                -69 119 -127 119 -130z"/>
                </g>
            </svg>
        </span>
    </a>
    <a *ngIf="cart?.selectedItems?.length" [ngClass]="cart?.selectedItems?.length ? 'active' : '' " class="data-ui cart" href="javascript:void(0)"
     (click)="bookingService.toggleMobileCart()" >
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
            </svg>
        </span>
    </a>
    
    <a class="data-ui sign-in" href="javascript:void(0)" [ngClass]="user ? 'active' : '' " (click)="menuToggle()">
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
            </svg>
        </span>
    </a>
    <ul class="loggedin-menu user-pop" *ngIf="showSubMenu">
        <li *ngIf="user"><b>Hi, {{user ? user.firstName : ''}}</b></li>
        <li *ngIf="user" routerLink="/dashboard">My Dashboard</li>
        <li routerLink="/booking">Book a Facial</li>
        <li routerLink="/nutrition">Book a Nutrition</li>
        <li routerLink="/membership/selection">Buy Membership</li>
        <li routerLink="/giftcards">Buy Gift Card</li>
        <li routerLink="/packages">Buy Package</li>
        <li *ngIf="user" (click)="logout()">Sign Out</li>
        <li *ngIf="!user" routerLink="/auth/login">Sign In</li>
    </ul>

   
