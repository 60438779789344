import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule  }   from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { CommonModule } from '@angular/common';
import { AppointmentComponent } from './dashboard/appointment/appointment.component';
import { DashboardHeaderComponent } from './dashboard/dashboard-header/dashboard-header.component';
import { PastAppointmentsPipe, UpcomingAppointmentsPipe } from './dashboard/appointments.pipe';
import { AccountComponent } from './dashboard/account/account.component';
import { MembershipsComponent } from './dashboard/memberships/memberships.component';
import { FilterMembershipPipe, MembershipServicesPipe } from './dashboard/memberships/filter-membership.pipe';
import { MembershipProductsComponent } from './dashboard/membership-products/membership-products.component';
import { AppointmentTileComponent } from './dashboard/appointment/appointment-tile/appointment-tile.component';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule, SPINNER } from "ngx-ui-loader";
import { ServiceNotificationComponent } from './service-notification/service-notification.component';
import { ServiceAddonNotificationComponent } from './service-addon-notification/service-addon-notification.component';
import { RescheduleComponent } from './dashboard/reschedule/reschedule.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedComponentModule } from './shared-component/shared-component.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AlertComponent } from './shared-component/alert/alert.component';
import { BookingModule } from './booking/booking.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NutritionModule } from './nutrition/nutrition.module';
import { LocationPipe } from './dashboard/account/location.pipe';
import { MembershipModule } from './membership/membership.module';
import { GiftcardModule } from './giftcard/giftcard.module';
import { StaffShortingPipe } from './dashboard/account/staff-shorting.pipe';
import { SortAppointmentServicesPipe } from './dashboard/appointment/appointment-tile/sort-appontment-services.pipe';
import { provideUserIdleConfig } from 'angular-user-idle';
import { PackageModule } from './package/package.module';



@NgModule({
  declarations: [
    AppComponent,
    AppointmentComponent,
    DashboardHeaderComponent,
    PastAppointmentsPipe,
    UpcomingAppointmentsPipe,
    AccountComponent,
    MembershipsComponent,
    FilterMembershipPipe,
    MembershipServicesPipe,
    MembershipProductsComponent,
    AppointmentTileComponent,
    ServiceNotificationComponent,
    ServiceAddonNotificationComponent,
    RescheduleComponent,
    LocationPipe,
    StaffShortingPipe,
    SortAppointmentServicesPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    MdbCheckboxModule,
    MdbModalModule,
    CommonModule,
    HttpClientModule,
    NgxUiLoaderModule,
  /*   NgxUiLoaderModule.forRoot({
      //logoUrl: 'assets/loading_blinking.gif',
      // fgsType: SPINNER.threeStrings,
      fgsSize: 0,
      // "fgsPosition": "bottom-center",
    }),
    NgxUiLoaderHttpModule.forRoot({
      exclude: [
        "/remove_item_in_cart"
      ],
      showForeground:true,
    }), */
    DashboardModule,
    NutritionModule,
    MembershipModule,
    BookingModule,
    GiftcardModule,
    PackageModule,
    SharedComponentModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: AlertComponent,
      positionClass:'toast-top-right',
      tapToDismiss:true,
    }),
    NgxMaskDirective, 
    NgxMaskPipe
  ],
  exports:[ SharedComponentModule ],
  providers: [provideNgxMask(), provideUserIdleConfig({ idle: 5, timeout: 86400, ping: 1 })],
  bootstrap: [AppComponent]
})
export class AppModule { }
