import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared-component/shared.service';
import { AuthService } from '../../auth/auth.service';
import { BookingService } from '../booking.service';
import { TrackingService } from '../tracking.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { DisadleContinueService } from 'src/app/disadle-continue.service';
@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  paymentForm!: FormGroup;
  userInfoForm!: FormGroup;
  couponForm!: FormGroup;

  cart: any;
  availablePaymentMethods: any = [];
  // togglePaymentMethodForm:boolean = false;
  user: any = [];
  isLoggedin:boolean=false;
  getaFree:boolean=false;
  disableContinue = false;
  subscription: Subscription = new Subscription;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this._buildForm();
    this.authService.$AuthUser.subscribe((user: any) => {
      if (user) {
        this.isLoggedin=true;
        this._patchAdditionalInfoForm(user);
        // this.bookingService.takeCartOwnership().subscribe((res:any)=>{
        //   if(!res.errors){
        //     this.bookingService.updateCartDetail();
        //   }
        // });

      }
    });
    this.bookingService.updateCartDetail();
    // this.bookingService.updateCartDetail();
    this.bookingService.clientCart$.subscribe((cart) => {
      if (cart && cart.id) {
        this.cart = cart;
        this.availablePaymentMethods = cart.availablePaymentMethods;
        this._patchCouponForm(cart.offers);
        if (cart.selectedItems.length == 0) {
          window.location.href = "/booking/services";
        }
      }
    })
    this.subscription = this.disadleContinueService.disableContinue$.subscribe(value => {
      this.disableContinue = value;
    });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  constructor(private trackingService: TrackingService, private formBuilder: FormBuilder, 
    public bookingService: BookingService, public authService: AuthService, private router: Router, 
    public sharedService: SharedService,private disadleContinueService:DisadleContinueService) {
    setTimeout(() => {
      this.trackingService.initiateCheckout(this.cart);
    }, 3000);
  }

  cvvValidator(control: FormControl): { [key: string]: any } | null {
    const valid = /^\d{3,4}$/.test(control.value);
    return valid ? null : { invalidCVV: true };
  }

  expiryValidator(control: FormControl): { [key: string]: any } | null {
    if (control.value) {
      const splitVal = control.value.split('/')
      const month = Number(splitVal[0]);
      const year = Number(splitVal[1]);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const valid = year >= currentYear && splitVal[0].length && ((currentYear == year && month >= currentMonth) || (year > currentYear && month <= 12));
      return valid ? null : { invalidExpiry: true };
    } else {
      return { invalidExpiry: true };
    }
  }

  maskExpiryDate(expiryDate: string): string {
    // Extract the month and year from the expiry date string
    const [month, year] = expiryDate.split('/');

    // Replace the year with asterisks
    const maskedYear = year;

    // Concatenate the masked month and year with a forward slash
    const maskedExpiryDate = `${month}/${maskedYear}`;

    return maskedExpiryDate;
  }

  onExpiryDateInput(): void {
    // Get the raw input value from the input field
    const rawValue = this.paymentForm.value.expiry.replace(/\D/g, '');

    // Extract the month and year components from the raw input value
    const month = rawValue.substr(0, 2);
    const year = rawValue.substr(2, 4);

    // Mask the expiry date
    const maskedExpiryDate = this.maskExpiryDate(`${month}/${year}`);

    // Update the input field value with the masked expiry date
    this.paymentForm.patchValue({
      expiry: maskedExpiryDate
    });
  }

  _buildForm() {
    // PaymentForm
    this.paymentForm = this.formBuilder.group({
      name: ['', Validators.required],
      number: ['', Validators.compose([Validators.required, Validators.maxLength(19), Validators.minLength(9)])],
      cvv: ['', Validators.compose([Validators.required, Validators.maxLength(4), Validators.minLength(3), this.cvvValidator])],
      expiry: ['', Validators.compose([Validators.required, Validators.maxLength(7), Validators.minLength(7), this.expiryValidator])],
      postal_code: ['', Validators.compose([Validators.required, Validators.maxLength(5), Validators.minLength(5)])],
    });

    // userInfoForm
    this.userInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      // dob: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      mobilePhone: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(10)])],
      note: [''],
    });

    // couponForm
    this.couponForm = this.formBuilder.group({
      promoCode: ['', Validators.required]
    });
  }

  _patchCouponForm(offers: any) {
    if (offers.length) {
      let code = offers[0].code;
      this.couponForm.patchValue({
        promoCode: code
      });
      this.couponForm.disable();
    } else if (this.sharedService.getLocalStorageItem('coupon')) {
      this.couponForm.patchValue({
        promoCode: this.sharedService.getLocalStorageItem('coupon')
      });
      this.autoApplyPromoCode();
    } else {
      this.couponForm.patchValue({
        promoCode: ''
      });
      this.couponForm.enable();
    }
  }

  _patchAdditionalInfoForm(user: any) {
    this.userInfoForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobilePhone: this.sharedService.getLast10digits(user.mobilePhone) || this.sharedService.getLast10digits(user.phoneNumber),
      note: user.clientNote ? user.clientNote : ''
    });
    this.userInfoForm.controls.email.disable();
  }

  updatePaymentMethod() {
    return new Promise((resolve, reject) => {
      if (this.paymentForm.valid) {
        this.bookingService.tokenizeCard(this.paymentForm.value).subscribe((res: any) => {
          if (res.token) {
            this.bookingService.addCartPaymentMethod(res.token).subscribe((res: any) => {
              if (!res.errors) {
                // this.bookingService.updateCartDetail();
                resolve(true);
                this.paymentForm.reset();
                // this.togglePaymentMethodForm = false;
              } else {
                reject(res.errors);
              }
            });
          } else {
            reject();
          }
        }, err => {
          let message = 'Please add correct card';
          if (err?.error?.errors?.number_last4?.length) {
            message = 'Please check your card number'
            this.paymentForm.controls['number'].setErrors({ invalidCard: true });
          }
          const title = 'Incorrect payment information';
          this.sharedService.showNotification(title, message);
          reject(err);
        })
      } else {
        this.paymentForm.markAllAsTouched();
        reject(false);
        const title = 'Incorrect payment information';
        const message = 'Please add correct card';
        this.sharedService.showNotification(title, message);
      }
    });
  }

  selectPaymentMethod(card: any) {
    this.bookingService.selectPaymentMethod(card.id).subscribe((res: any) => {
      if (!res.errors) {
        card.active = true;
        this.bookingService.updateCartDetail();
      }
    })
  }

  updateUserInfo() {
    return new Promise((resolve, reject) => {
      if (this.userInfoForm.valid) {
        const client = this.userInfoForm.value;
        client.email = this.userInfoForm.controls['email'].value;
        this.bookingService.updateClientCartInfo(client).subscribe((res: any) => {
          if (!res.errors) {
            resolve(true);
            // this.bookingService.updateCartDetail();
          } else {
            reject();
          }
        });
      } else {
        this.userInfoForm.markAllAsTouched();
        reject();
        const title = 'Incorrect user information';
        const message = 'Please fill the correct additional information';
        this.sharedService.showNotification(title, message);
      }
    })
  }

  updateCartDetail() {
    return new Promise((resolve, reject) => {
      const cartId = this.sharedService.getLocalStorageItem('cartId');
      if (cartId) {
        this.bookingService.getCartDetail().subscribe((res: any) => {
          if (!res.errors) {
            resolve(true);
            this.bookingService.clientCart$.next(res.data.cart);
          } else {
            reject();
          }
        });
      } else {
        reject();
      }
    })
  }

  autoApplyPromoCode() {
    const coupon = this.sharedService.getLocalStorageItem('coupon');
    if (coupon) {
      this.bookingService.addCartOffer(coupon).subscribe((res: any) => {
        if (!res.errors && res?.data?.addCartOffer?.offer?.applied) {
          const title = 'Promo Code';
          const message = 'applied successfully';
          this.sharedService.showNotification(title, message);
          this.bookingService.updateCartDetail();
          this.sharedService.removeLocalStorageItem('coupon');
        } else {
          const title = 'Invalid promo code';
          const message = 'Please enter valid promo code';
          this.sharedService.showNotification(title, message);
          !res?.data?.addCartOffer?.offer?.applied ? this.bookingService.removeCartOffer(res?.data?.addCartOffer?.offer?.id).subscribe() : null;
        }
      })
    }
  }


  applyPromoCode() {
    const code = this.couponForm.value.promoCode;
    if (this.couponForm.valid && code != '') {
      this.bookingService.addCartOffer(code).subscribe((res: any) => {
        if (!res.errors && res?.data?.addCartOffer?.offer?.applied) {
          const title = 'Promo Code';
          const message = 'applied successfully';
          this.sharedService.showNotification(title, message);
          this.bookingService.updateCartDetail();
        } else {
          const title = 'Invalid promo code';
          const message = 'Please enter valid promo code';
          this.sharedService.showNotification(title, message);
          !res?.data?.addCartOffer?.offer?.applied ? this.bookingService.removeCartOffer(res?.data?.addCartOffer?.offer?.id).subscribe() : null;
        }
      })
    } else {
      this.couponForm.markAllAsTouched();
      const title = 'Incorrect promo code';
      const message = 'Please fill the correct promo code';
      this.sharedService.showNotification(title, message);
    }
  }
  getUniquePaymentMethods(paymentMethods: any[]): any[] {
    const uniqueCards = paymentMethods.filter((card, index, self) =>
      index === self.findIndex((t) => (
        t.cardLast4 === card.cardLast4 && t.cardBrand === card.cardBrand
      ))
    );
    return uniqueCards;
  }

  removePromoCode() {
    let offerId = this.cart.offers[0].id;
    if (offerId) {
      this.bookingService.removeCartOffer(offerId).subscribe((res: any) => {
        if (!res.errors) {
          const title = 'Promo Code';
          const message = 'removed successfully';
          this.sharedService.showNotification(title, message);
          this.bookingService.updateCartDetail();
        }
      })
    }
  }

  easyCheckout() {
    // this.togglePaymentMethodForm = true;
    this.bookingService.ngxUiLoaderService.start();
    this.userInfoForm.markAllAsTouched();
    this.paymentForm.markAllAsTouched();
    window.scrollTo(0, 0);
    if (this.cart?.selectedItems[0]?.selectedPaymentMethod?.id) { // If payment method is selected by user
      this.updateUserInfo().then((status: any) => {
        if (status) {
          this.updateCartDetail().then((status: any) => {
            if (status) {
              this.checkout();
            }
          }).catch(err => {
            console.log(err);
          });
        }
      }).catch(err => {
        console.log(err);
      });
    } else {
      this.updatePaymentMethod().then((status: any) => { // If payment method is needed to be add
        if (status) {
          this.updateUserInfo().then((status: any) => {
            if (status) {
              this.updateCartDetail().then((status: any) => {
                if (status) {
                  this.checkout();
                  this.bookingService.ngxUiLoaderService.stop();
                }
              }).catch(err => {
                console.log(err);
                this.bookingService.ngxUiLoaderService.stop();
              });
            }
          }).catch(err => {
            console.log(err);
            this.bookingService.ngxUiLoaderService.stop();
          });
        }
      }).catch(err => {
        this.bookingService.ngxUiLoaderService.stop();
        const title = 'Payment Method Failed';
        //const message = err[0]?.message;
        const message = "Please check your payment zip code and CVV, then try again.";
        this.sharedService.showNotification(title, message);
      });
    }
  }
  onCheckboxChange(event: any){
 /*  const email = this.userInfoForm.get('email')?.value;
  const firstName = this.userInfoForm.get('firstName')?.value;
  const lastName = this.userInfoForm.get('lastName')?.value;
  const phone = this.userInfoForm.get('mobilePhone')?.value;
  const payload = {
    email:email,
    firstName: firstName,
    lastName: lastName,
    phone: phone
  }; 
  this.bookingService.addToKlaviyo(payload).subscribe((res: any) => { 
    console.log("res",res);
  }); */
  const isChecked = event.target.checked;
  console.log('Checkbox is checked:', isChecked);
  
  if (isChecked) {
    this.getaFree=true;
  } else {
    this.getaFree=false;
  }
  console.log("this.getaFree",this.getaFree);
}
  checkout() {
    if (this.cart.clientInformation) {
      this.bookingService.checkoutCart().subscribe((res: any) => {
        if (!res.errors) {
          this.bookingService.sendBookingMail(this.cart).subscribe();
          this.bookingService.ngxUiLoaderService.stop();
          const title = 'Appointment Booked';
          const message = 'your appointment is scheduled successfully.';
          this.sharedService.showNotification(title, message);
           //Add to Klaviyo [START]
           if(this.getaFree){
          const email = this.userInfoForm.get('email')?.value;
          const firstName = this.userInfoForm.get('firstName')?.value;
          const lastName = this.userInfoForm.get('lastName')?.value;
          const phone = this.userInfoForm.get('mobilePhone')?.value;
          const payload = {
            email:email,
            firstName: firstName,
            lastName: lastName,
            phone: phone
          }; 
          this.bookingService.addToKlaviyo(payload).subscribe((res: any) => { 
            console.log("res",res);
          });
        }
          //Add to Klaviyo [END]
         
          this.router.navigateByUrl('/booking/congrats');
          this.bookingService.checkoutBookingResponse$.next(res.data.checkoutCart);
          this.sharedService.removeLocalStorageItem('cartId');
          this.sharedService.removeLocalStorageItem('selectedLocation');
          this.sharedService.removeLocalStorageItem('guestSet');
          this.bookingService.updateCartDetail();

        } else {
          this.bookingService.ngxUiLoaderService.stop();
          const title = 'Checkout Error';
          const message = res.errors[0].message;
          this.sharedService.showNotification(title, message);
        }
      })
    } else {
      this.bookingService.ngxUiLoaderService.stop();
      const title = 'Additional information is required';
      const message = 'please save the additional information';
      this.sharedService.showNotification(title, message);
    }
  }
  async onEmailKeyup(email: string) {
    this.bookingService.ngxUiLoaderService.start();
    if (this.userInfoForm.controls.email.valid) {
      try {
        const clientDetail: any = await firstValueFrom(this.bookingService.getClientByEmail(email));
        let firstName = clientDetail.data.clients.edges[0]?.node.firstName;
        let lastName = clientDetail.data.clients.edges[0]?.node.lastName;
        let mobilePhone = clientDetail.data.clients.edges[0]?.node.mobilePhone.substring(2);
        // Patch the first name into the form control
        this.userInfoForm.patchValue({
          firstName: firstName || '',
          lastName: lastName || '',
          mobilePhone: mobilePhone || ''
        });
        /* if (firstName) {
          this.userInfoForm.controls.firstName.disable();  // Disable if firstName exists
        } else {
          console.log("Enable fname called");
          this.userInfoForm.controls.firstName.enable();  // Enable if firstName is empty or undefined
        }
        // Enable or disable the lastName field based on the availability of the value
        if (lastName) {
          this.userInfoForm.controls.lastName.disable();  // Disable if lastName exists
        } else {
          this.userInfoForm.controls.lastName.enable();  // Enable if lastName is empty or undefined
        }

        // Enable or disable the mobilePhone field based on the availability of the value
        if (mobilePhone) {
          this.userInfoForm.controls.mobilePhone.disable();  // Disable if mobilePhone exists
        } else {
          this.userInfoForm.controls.mobilePhone.enable();  // Enable if mobilePhone is empty or undefined
        } */
        this.bookingService.ngxUiLoaderService.stop();
      } catch (error) {
        console.error('Error fetching client details', error);
        this.bookingService.ngxUiLoaderService.stop();
      }
    }
  }
}
